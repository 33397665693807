//import process from "process";
//process.config();

const host = window.location.host;

const mode = {
    'localhost:3000': 'local',
    'sssdivyasmrti.book': 'dev',
    'stagingbooking.sssdivyasmrti.org': 'staging',
    'booking.sssdivyasmrti.org': 'production'
}[host];

const apihost = {
    local: 'http://localhost:2052',
    dev: 'http://sssdivyasmrti.api',
    staging: 'https://stagingapi.sssdivyasmrti.org',
    production: 'https://api.sssdivyasmrti.org',
}[mode];

const homeurl = {
    local: 'http://localhost:3001',
    dev: 'http://sssdivyasmrti.local',
    staging: 'https://staging.sssdivyasmrti.org',
    production: 'https://www.sssdivyasmrti.org',
}[mode];


const adminurl = {
    local: 'http://localhost:3030',
    dev: 'http://sssdivyasmrti.admin',
    staging: 'https://stagingmanage.sssdivyasmrti.org',
    production: 'https://manage.sssdivyasmrti.org',
}[mode];


/*
const authurl = {
    local: 'stagingauth.sssdivyasmrti.org',
    dev: 'stagingauth.sssdivyasmrti.org',
    staging: 'stagingauth.sssdivyasmrti.org',
    production: 'auth.sssdivyasmrti.org',
}[mode];
*/

//

//


const env = {
    site: {
        host: host,
        title: 'Sri Sathya Sai Divyasmṛti > Booking',
        description: "Sri Sathya Sai Divyasmṛti Trust was founded by Bhagawan Sri Sathya Sai Baba on 2nd September, 1972. Under Bhagawan's guidance, the Trust has been undertaking a number of welfare activities such as providing free education at school and university levels, delivering quality medical care at primary, secondary and tertiary levels completely free of charge, supply of pure drinking water in various regions of the state of Andhra Pradesh and the metropolitan city of Chennai.",
        keywords: "Sathya Sai Baba Divyasmṛti website, Bhagavan Sri Sathya Sai Baba, Sathya Sai baba teachings, abode of Bhagavan Baba, avatars, selfless service, seva, ngo, non-governmental organization, sai baba, Sai Bhajans, Sri Sathya Sai Central trust, Official Sathya sai baba, sai baba official, prasanthi nilayam, sai baba miracles, sai devotees experience, baba, sai, sai baba, sai bhajan, sai baba songs, puttaparthi, sai baba bhajan, sai baba answers, sathya sai, Sai baba photos, Sai baba live, Sai baba live darshan, sathya sai baba, sri sathya sai, sathya sai bhajans, sathya sai baba bhajans, sathya sai baba photos, sathya sai baba quotes, sathya sai baba images, sathya sai baba songs, sathya sai baba discourse, sathya sai baba teachings, sathya sai baba life story, sathya sai baba message,  sathya sai baba work, sai seva, sevadal, Prashanthi mandir bhajans, Prashanthi nilayam live, Puttaparthi Sai Baba, Sai hospital,  Sathya Sai Super speciality hospitals, Vahinis, Chinnakathas,Sathya sai water projects, Sathya Sai education, Education in Human Values, volunteer, sevadal"
    },
    api: {
        host: apihost,
    },
    siteurl: `${window.location.protocol}\\\\${host}`,
    homeurl: homeurl,
    adminurl: adminurl,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': ''
    },
    firebase: {
        accessCode: {
            url: window.location.href,
            handleCodeInApp: true,
            iOS: {
                bundleId: 'org.sssmediacentre.ios'
            },
            android: {
                packageName: 'org.sssmediacentre.android',
                installApp: true,
                minimumVersion: '12'
            },
            dynamicLinkDomain: 'auth.sssdivyasmrti.org'
        },
        config: {
            apiKey: "AIzaSyATB3ixWAeNfXLZ3VdNJGR18Fm5DKG4Nmg",
            authDomain: "sssmediacentre-brindavan.firebaseapp.com",
            projectId: "sssmediacentre-brindavan",
            storageBucket: "sssmediacentre-brindavan.appspot.com",
            messagingSenderId: "361856338300",
            appId: "1:361856338300:web:55635870c75bf7b57cbcf9",
            measurementId: "G-SQVXY2VLND"
        }
    },
    textlocal: {
        default:    {
                api: 'MzY3YTc5NjMzNDY1NTg0ZDYzNDg2NDcxNzY0Yjc4NDg%3D',
                host: `https://api.textlocal.in`,
                inbox: 1296778,
                keyword: 'SAIRAM Divyasmrti',
                number: '9220592205'
        },
        local:  {
            api: 'MzY3YTc5NjMzNDY1NTg0ZDYzNDg2NDcxNzY0Yjc4NDg%3D',
            host: `https://api.textlocal.in`,
            inbox: 1296778,
            keyword: 'SAIRAM Divyasmrti',
            number: '9220592205'
        },
        global:  {
            api: 'MzY3YTc5NjMzNDY1NTg0ZDYzNDg2NDcxNzY0Yjc4NDg%3D',
            host: `https://api.textlocal.in`,
            inbox: 1296778,
            keyword: 'SAIRAM Divyasmrti',
            number: '+919220592205'
        },
    },
    database: {
        prefix: 'brindavan_'
    }


}

export default env;